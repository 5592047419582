<template>
  <div ref="body">
    <custom-header active="" :subActive="current"></custom-header>

    <div class="content-container ">
      
      <div class="left-bar ">
        <div class="serch-bar flex">
          <div class="serch-box flex align-center">
            <van-icon name="search" />
            <input type="search" v-model="keyword" placeholder="请输入搜索关键词" @keyup.enter="onSearch">
          </div>
          <button class="search-btn" @click="onSearch">搜 索</button>
        </div>
        <div class="title text-bold">活动</div>
        <div class="main-menu">
          <div class="row">
            <template v-for="(item, idx) in themes">
              <div :key="idx" :class="['theme', partsId == item.partsId ? 'active' : '']" @click="partChange(item, idx)"><h1>{{item.partsName}}</h1></div>
            </template>
          </div>
        </div>
        <div class="pavilion-box1">
          <div class="row">
            <div :class="['item', !activityType ? 'active' : '']" @click="categoryChange(0)"><h1>全部</h1></div>
            <template v-for="(item) in categorys">
              <div :key="item.id" :class="['item', activityType === item.id ? 'active' : '']" @click="categoryChange(item.id)"><h1>{{item.name}}</h1></div>
            </template>
          </div>
        </div>
      </div>
      
      <div class="activity-content">
        <div class="pavilion-box2 flex flex-wrap">
          <div :class="['item', !activityType ? 'active' : '']" @click="categoryChange(0)"><h1>全部</h1></div>
          <template v-for="(item) in categorys">
            <div :key="item.id" :class="['item', activityType === item.id ? 'active' : '']" @click="categoryChange(item.id)"><h1>{{item.name}}</h1></div>
          </template>
        </div>
        
        <div class="list">
          <van-empty v-if="activities.length === 0" description="" />
          <template v-else>
            <div class="" v-for="(item, i) in activities" :key="i">
              <!-- PC端 -->
              <div class="exhibitor flex">
                
                <!-- 偶数位: 0 2 4 -->
                <template v-if="(i % 2) === 0">
                  <div class="content-box" style="padding-left: 0;">
                    <div class="title text-bold" @click="showDetail(item.id)">{{item.activityName}}</div>
                    <div class="intro">{{item.activityIntroduce}}</div>
                  </div>
                  <div class="cover-box" @click="showDetail(item.id)">
                    <img :src="item.activityPic" :alt="item.activityName" class="cover">
                  </div>
                </template>
                
                <!-- 奇数位: 1 3 5 -->
                <template v-else>
                  <div class="cover-box" @click="showDetail(item.id)">
                    <img :src="item.activityPic" :alt="item.activityName" class="cover">
                  </div>
                  <div class="content-box" style="padding-right: 0;">
                    <div class="title text-bold" @click="showDetail(item.id)">{{item.activityName}}</div>
                    <div class="intro">{{item.activityIntroduce}}</div>
                  </div>
                </template>

              </div>
              
              <!-- 移动端 -->
              <div class="exhibitor1 flex">
                <div class="cover-box" @click="showDetail(item.id)">
                  <img :src="item.activityPic" :alt="item.activityName" class="cover">
                </div>
                <div class="content-box" style="padding-right: 0;">
                  <div class="title text-bold text-cut" @click="showDetail(item.id)">{{item.activityName}}</div>
                  <div class="intro">{{item.activityIntroduce}}</div>
                </div>
              </div>
            </div>
            <div class="pagination-box flex justify-center">
              <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
            </div>
          </template>
        </div>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getThemeList, getActivityList } from '@/api/index.js'

export default {
  // name: '活动',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'activity',
      categorys: [
        {id: 1, name: '品宣活动'},
        {id: 2, name: '设计活动'},
        {id: 3, name: '艺术活动'},
        {id: 4, name: '共创活动'},
      ],
      activityType: 0,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      pages: 1,
      keyword: '',
      partsId: '',
      themes: [],
      activities: [],
    }
  },
  created() {
    this.getThemes();
    this.getActivities();
  },
  methods: {
    onSearch() {
      this.pageNo = 1;
      this.getActivities();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getActivities();
    },
    categoryChange(id) {
      if (id === this.activityType) return;
        
      this.activityType = id;
      this.onSearch();
    },
    partChange(row) {
      if (row.partsId == this.partsId) return;
        
      this.partsId = row ? row.partsId : '';
      this.onSearch();
    },
    showDetail(id) {
      // this.$router.push({
      //   path: '/activityDetail',
      //   query: {
      //     id
      //   }
      // })
    },
    getThemes() {
      getThemeList().then(res => {
        this.themes = res.resultData || [];
        // if (this.themes.length > 0) {
        //   this.partsId = this.themes[0].partsId;
        //   this.getActivities();
        // }
      })
    },
    getActivities() {
      let params = {
        partsId: this.partsId,
        activityType: this.activityType,
        keyword: this.keyword,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      getActivityList(params).then(res => {
        let {records = [], total = 0, pages = 0 } = res.resultData;
        this.activities = records;
        this.pages = pages;
        this.total = total;
      })
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 0 10px 10px;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 55px;
          height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 13px;
        }
        
        .serch-box {
          width: 290px;
          height: 30px;
          padding: 0 10px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 4px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 13px;
            padding-left: 5px;
          }
        }
    
        .van-icon-search {
          font-size: 16px;
          font-weight: bold;
        }
      }
      
      .title {
        display: none;
      }
      
      .main-menu {
        width: 100%;
        overflow-x: auto;
        
        &::-webkit-scrollbar {
          height: 0;
        }
        .row {
          width: auto;
          white-space: nowrap; 
          
          .theme {
            display: inline-block;
            width: auto;
            text-align: center;
            border-radius: 4px;
            text-align: center;
            background-color: #fff;
            margin-right: 10px;
            
            &.active {
              h1 {
                background-color: #060211;
                color: #fff;
              }
            }
            h1 {
              line-height: 30px;
              font-weight: normal;
              font-size: 12px;
              padding: 0 10px;
              border-radius: 4Px;
            }
            
          }
        }
        
      }
      
    }
    
    .pavilion-box2 {
      display: none;
    }
    .pavilion-box1 {
      margin: 10px 0;
      width: 100%;
      overflow-x: auto;
      
      &::-webkit-scrollbar {
        height: 0;
      }
      
      .row {
        width: auto;
        white-space: nowrap; 
        
        .item {
          display: inline-block;
          width: auto;
          text-align: center;
          border-radius: 4px;
          text-align: center;
          background-color: #fff;
          margin-right: 10px;
          
          &.active {
            h1 {
              background-color: #060211;
              color: #fff;
            }
          }
          h1 {
            line-height: 30px;
            font-weight: normal;
            font-size: 12px;
            padding: 0 10px;
            border-radius: 4Px;
          }
          
        }
      }
      
    }
    
    .activity-content {
      width: 375px;
      
      .list {
        
        /deep/ .van-empty__image {
          width: 100%;
          height: auto;
        }
        
        .exhibitor {
          display: none;
        }
        .exhibitor1 {
          width: 375px;
          padding: 0 10px;
          margin-bottom: 20px;
          
          .cover-box {
            width: 135px;
            
            .cover {
              display: block;
              width: 100%;
              height: auto;
              border-radius: 4px;
            }
          }
          
          .content-box {
            width: 215px;
            padding: 0 10px;
            box-sizing: border-box;
            
            .title {
              font-size: 14px;
              margin-bottom: 5px;
            }
            
            .intro {
              font-size: 13px;
              line-height: 1.5em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          
        }
      
      }
      
    }
    
    .pagination-box {
      margin-top: 20px;
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 14px;
          height: 24px;
          line-height: 24px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 24px;
          border-radius: 4px;
          padding: 0 5px;
          font-size: 14px;
          height: 24px;
          line-height: 24px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 24px;
        }
      }
    
    }
    
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    display: flex;
    width: 280px;
    margin: 20px auto ;
    
    .left-bar {
      width: 80px;
      padding: 0 10px 10px;
      box-sizing: border-box;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 16px;
          height: 8px;
          border-radius: 1px;
          padding: 0;
          font-size: 3px;
        }
        
        .serch-box {
          width: 54px;
          height: 8px;
          padding: 2px 3px;
          margin-right: 2px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 1px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 3px;
            padding-left: 2px;
          }
        }
    
        .van-icon-search {
          font-size: 4.8px;
          font-weight: bold;
        }
      }
      
      .title {
        font-size: 6px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }
      
      .theme {
        width: 60px;
        margin: 2px 0;
        cursor: pointer;
        text-align: center;
        border-radius: 1px;
        border: 0.5px solid #fff;
        line-height: 12px;
        
        &.active,
        &:hover {
          border-color: #060211;
        }
        h1 {
          font-weight: normal;
          font-size: 4px;
          font-family: 'SourceHanSansCN-Medium';
        }
        
      }
      
    }
    
    .pavilion-box1 {
      display: none;
    }
    .activity-content {
      width: 200px;
      padding-left: 10px;      
      
      .pavilion-box2 {
        margin-bottom: 7px;
        
        .item {
          margin: 0 1px 1px;
          cursor: pointer;
          text-align: center;
          background-color: #fff;
          
          &.active {
            h1 {
              background-color: #060211;
              color: #fff;
            }
          }
          h1 {
            height: 10px;
            line-height: 10px;
            font-weight: normal;
            font-size: 3.6px;
            padding: 0 4px;
            border-radius: 3Px;
            
            &:hover {
              background-color: #060211;
              color: #fff;
            }
          }
          
        }
        
      }

      .list {
        
        /deep/ .van-empty__image {
          width: 40%;
          height: auto;
        }
      }
      
      .exhibitor1 {
        display: none;
      }
      .exhibitor {
        width: 190px;
        margin-bottom: 12px;
        
        
        .cover-box {
          width: 95px;
          // cursor: pointer;
          
          .cover {
            display: block;
            width: 100%;
            border-radius: 1px;
          }
        }
        
        .content-box {
          width: 95px;
          padding: 0 4px;
          box-sizing: border-box;
          
          .title {
            font-size: 4.8px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-weight: bold;
            // cursor: pointer;
          }
          
          .intro {
            font-size: 3.8px;
            line-height: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
          }
        }
        
      }
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 3.6px;
          font-weight: 700;
          height: 8px;
          line-height: 8px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 8px;
          border-radius: 1px;
          padding: 0 1px;
          font-size: 3.6px;
          height: 8px;
          line-height: 8px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 8px;
        }
      }
    
    }
    
  }
}

  
</style>